<template>
	<div class="page-wrap">
    <h3 class="">服务费</h3>
    <h1 class="">1000元</h1>
    <el-button size="small" type="primary" style="padding:12px 64px;">立即支付</el-button>
	</div>
</template>
<script>
export default {
  name: 'PayView'
}
</script>
<style lang="scss" scoped>
.page-wrap{
	padding: 120px 0 50px;

  button{
		margin-top: 150px;
	}
}
</style>