<template>
  <div class="page-wrap">
    <div class="page-header">
      <div class="page-header-logo">
        <div class="logo-name">北京环旅巴扎科技有限公司</div>
        <div class="page-header-nav">
          <span @click="toPage('introduce')">业务介绍</span>
          <span @click="toPage('information')">行业资讯</span>
        </div>
<!--        <img src="../assets/images/logo.png" alt="logo">-->
        <div class="page-header-tools">
<!--          <el-button class="support-btn" size="mini" plain @click="scanDialogVisible = true">Technical Support</el-button>-->
          <el-button size="mini" plain @click="onLogin('登录')">登录</el-button>
          <el-button size="mini" plain @click="onLogin('注册')">注册</el-button>
        </div>
      </div>
    </div>
    <div class="page-banner">
      <el-carousel loop :interval="5000" arrow="always" indicator-position="none">
        <el-carousel-item v-for="(item, index) in banner" :key="index">
          <img :src="item.img" alt="banner">
          <div class="page-banner-content">
            <div class="page-banner-title">{{item.title}}</div>
            <div class="page-banner-subtitle">{{item.subTitle}}</div>
            <div class="page-banner-line">
              <span></span>
            </div>
            <div class="page-banner-info">{{item.content}}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <img class="icon-aircraft" src="../assets/images/image-aircraft.png" alt="">
    </div>
    <div class="page-content">
      <div class="page-nav-header" ref="introduce">业务介绍</div>
      <div class="page-nav">
        <div class="page-nav-item">
          <div class="page-nav-icon">
            <i class="el-icon-link"></i>
          </div>
          <div class="page-nav-title">快速链接</div>
          <div class="page-nav-info">环旅巴扎使航空公司能够高效地与NDC连接到中国的主要渠道，并方便地维护授权。无论您使用的是哪种版本，TripBazar都会处理连接，帮助您快速实现业务目标。</div>
        </div>
        <div class="page-nav-item">
          <div class="page-nav-icon">
            <i class="el-icon-shopping-cart-1"></i>
          </div>
          <div class="page-nav-title">支持多类型产品</div>
          <div class="page-nav-info">环旅巴扎的网络和市场可以帮助航空公司销售广泛的产品，包括机票，以及座位选择、预付行李和其他辅助服务，充分发挥航空公司NDC的优势。</div>
        </div>
        <div class="page-nav-item">
          <div class="page-nav-icon">
            <i class="el-icon-video-play"></i>
          </div>
          <div class="page-nav-title">丰富的内容展示</div>
          <div class="page-nav-info">环旅巴扎平台可以充分展示您的产品优势，通过NDC API提供的详细描述和各种多媒体信息，使产品更具吸引力。让乘客关注的不仅仅是价格，还有价值。</div>
        </div>
        <div class="page-nav-item">
          <div class="page-nav-icon">
            <i class="el-icon-location-outline"></i>
          </div>
          <div class="page-nav-title">本地化服务</div>
          <div class="page-nav-info">我们最熟悉当地市场，我们知道如何高效地推广您的业务，我们有足够的资源支持本地化。</div>
        </div>
      </div>
    </div>
    <div class="page-content" style="padding-top: 50px;background: rgb(247, 248, 250);">
      <div class="page-nav-header" ref="information">行业资讯</div>
      <div class="page-list">
        <div class="page-list-item" @click="scanDialogVisible = true">
          <div class="page-list-label">如何提升该产品展示能力？</div>
          <div class="page-list-info">NDC使航空公司能够旅行代理和在线旅行社（OTA）展示其产品的全貌，包括各种辅助服务和套餐。它提供了更丰富的产品信息和更灵活的定价机制，使航空公司能够更详细地展示其差异化产品。</div>
        </div>
        <div class="page-list-item" @click="scanDialogVisible = true">
          <div class="page-list-label">如何增强个性化服务？</div>
          <div class="page-list-info">NDC使航空公司能够获取更多关于乘客的信息，从而提供更个性化的服务和产品推荐。根据乘客的传统数据和购买，航空公司可以推送定制的优惠和套餐，提升客户满意度和忠诚度。</div>
        </div>
        <div class="page-list-item" @click="scanDialogVisible = true">
          <div class="page-list-label">如何改进交易流程和效率？</div>
          <div class="page-list-info">NDC通过XML标准简化了航班和辅助服务分销流程，容器中间环节，从而提高了交易的复杂性和效率。这不仅降低了成本，还缩短了产品上线时间，使航空公司能够响应市场需求和竞争变化。</div>
        </div>
        <div class="page-list-item" @click="scanDialogVisible = true">
          <div class="page-list-label">如何促进直接分销渠道的发展？</div>
          <div class="page-list-info">NDC支持航空公司通过其官方网站、移动应用和其他直接渠道进行销售，减少对传统GDS的依赖。这有助于航空公司降低分销成本，同时与乘客建立更直接的联系，增强品牌忠诚度。</div>
        </div>
        <div class="page-list-item" @click="scapnDialogVisible = true">
          <div class="page-list-label">如何增强平台兼容性？</div>
          <div class="page-list-info">NDC采用开放标准，增强了不同系统之间的兼容性和互操作性。这意味着航空公司和旅行社可以更保密集成和使用不同的技术解决方案，减少技术壁垒，提高业务灵活性。</div>
        </div>
        <div class="page-list-item" @click="scanDialogVisible = true">
          <div class="page-list-label">如何支持创新商业模式？</div>
          <div class="page-list-info">NDC为航空公司提供了更多创新的商业模式。例如，航空公司可以通过动态定价、捆绑销售和订阅服务等方式，探索新的收入来源。此外，NDC还支持更多的合作，如与酒店、租车公司和旅游景点的联运销售，提供一站式旅行解决方案。</div>
        </div>
      </div>
    </div>
    <div class="page-footer-nav">
      <div class="page-footer-box">
        <div class="page-footer-panel">
          <div class="page-footer-panel-title">联系我们</div>
          <br/>
          <div class="page-footer-panel-item">
            <div class="page-footer-panel-label">联系电话:</div>
            <div class="page-footer-panel-value">18510559800</div>
          </div>
          <div class="page-footer-panel-item">
            <div class="page-footer-panel-label">电子邮箱:</div>
            <div class="page-footer-panel-value">support@tripbaza.com</div>
          </div>
          <div class="page-footer-panel-item">
            <div class="page-footer-panel-label">注册地址:</div>
            <div class="page-footer-panel-value">北京市海淀区丰慧中路7号新材料创业大厦10层10层北侧办公1715号</div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="page-footer-nav">-->
<!--      <div class="footer-nav-row">-->
<!--        <div class="footer-nav-item">-->
<!--          <img src="../assets/images/image-footer-nav-1.png" alt="Country">-->
<!--          <div class="footer-nav-item-value">7</div>-->
<!--          <div class="footer-nav-item-label">Country</div>-->
<!--        </div>-->
<!--        <div class="footer-nav-item">-->
<!--          <img src="../assets/images/image-footer-nav-2.png" alt="Airlines">-->
<!--          <div class="footer-nav-item-value">22</div>-->
<!--          <div class="footer-nav-item-label">Airlines</div>-->
<!--        </div>-->
<!--        <div class="footer-nav-item">-->
<!--          <img src="../assets/images/image-footer-nav-3.png" alt="Channels">-->
<!--          <div class="footer-nav-item-value">62</div>-->
<!--          <div class="footer-nav-item-label">Channels</div>-->
<!--        </div>-->
<!--        <div class="footer-nav-item">-->
<!--          <img src="../assets/images/image-footer-nav-4.png" alt="Amazing Features">-->
<!--          <div class="footer-nav-item-value">10</div>-->
<!--          <div class="footer-nav-item-label">Amazing Features</div>-->
<!--        </div>-->
<!--        <div class="footer-nav-item">-->
<!--          <img src="../assets/images/image-footer-nav-5.png" alt="Services">-->
<!--          <div class="footer-nav-item-value">7*24</div>-->
<!--          <div class="footer-nav-item-label">Services</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="page-footer">
      <div class="page-footer-content">
        <div class="page-footer-left">
          <div class="">
            联系电话：18510559800
          </div>
          <div class="">
            电子邮箱：support@tripbaza.com
          </div>
          <div class="">
            注册地址：北京市海淀区丰慧中路7号新材料创业大厦10层10层北侧办公1715号
          </div>
        </div>
        <div class="page-footer-right">
          <div class="">
            北京环旅巴扎科技有限公司 版权所有
            <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2024071787号-1</a>
          </div>
<!--          <div class="">网安备11010502036376号-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <el-dialog
      class="login-dialog"
      width="350"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="loginDialogVisible"
      @close="closeLoginDialog"
    >
      <el-form ref="form" :model="form" label-position="left" label-width="0">
        <el-form-item
          prop="account"
          label-width="0"
          :rules="[
            { required: true, message: '请输入账号', trigger: 'blur' },
          ]"
        >
          <el-input v-model="form.account" placeholder="请输入账号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          label-width="0"
          :rules="[
            { required: true, message: '请输入密码', trigger: 'blur' },
          ]"
        >
          <el-input v-model="form.password" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button type="primary" :loading="loginLoading" style="width: 100%;margin-top: 10px;" @click="login">{{btnName}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      class="pay-dialog"
      title="付费内容1000元/条"
      width="260"
      :visible.sync="scanDialogVisible"
    >
      <div>
        <img :src="this.qRCodeUrl" alt="qrcode">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
  name: 'IndexView',
  components: {
  },
  data() {
    return {
      btnName: '登录',
      banner: [{
        img: require('../assets/images/banner-1.png'),
        title: '为航空业开启NDC时代',
        subTitle: '连接世界和亚太地区',
        content: '技术进步是人类文明向更高文明水平迈进的动力，也是我们坚定不移的信念和追求，旅游业也是如此。我们在创新和发展领域探索NDC，致力于为所有航空和旅游行业带来更好的沟通和体验。'
      }, {
        img: require('../assets/images/banner-2.png'),
        title: '为航空业开启NDC时代',
        subTitle: '欢迎来到环旅巴扎',
        content: '技术进步是人类文明向更高文明水平迈进的动力，也是我们坚定不移的信念和追求，旅游业也是如此。我们在创新和发展领域探索NDC，致力于为所有航空和旅游行业带来更好的沟通和体验。'
      }],
      loginDialogVisible: false,
      scanDialogVisible: false,
      form: {
        account: '',
        password: ''
      },
      qRCodeUrl: '',
      loginLoading: false
    }
  },
  mounted() {
    const origin = window.location.origin
    QRCode.toDataURL(`${origin}/pay`, {
      errorCorrectionLevel: 'H',
      margin: 2,
    })
      .then(url => {
        this.qRCodeUrl = url
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    onLogin(name) {
      console.log(this.loginLoading)
      this.btnName = name
      this.loginDialogVisible = true
    },
    closeLoginDialog() {
      this.loginDialogVisible = false
      this.$refs['form'].resetFields()
    },
    login() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loginLoading = true
          setTimeout(() => {
            this.loginDialogVisible = false
            this.loginLoading = false
            this.$message.success('Success')
          }, 1000)
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    toPage(type) {
      let ref = this.$refs[type]

      if (ref) {
        ref.scrollIntoView({
          behavior: "smooth"
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-wrap{
  color: #ffffff;

  .page-header{
    position: relative;
    width: 100%;
    height: 120px;
    z-index: 1;
    text-align: left;
    .page-header-logo{
      display: flex;
      position: relative;
      max-width: 1200px;
      padding: 16px;
      margin: 0 auto;

      img{
        width: 100px;
      }

      .logo-name{
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
      }

      .page-header-nav{
        margin-left: 50px;
        line-height: 28px;
        color: #ffffff;

        span{
          margin-left: 24px;
          font-size: 16px;
          cursor: pointer;

          &:hover{
            font-weight: bold;
            color: rgb(64, 158, 255);
            border-bottom: 1px solid;
          }
        }
      }
    }

    .page-header-tools{
      position: absolute;
      right: 16px;
      top: 18px;

      button{
        background: transparent;
        color: #fff;
        border-color: #ffffff;

        &:active{
          color: #409eff;
          border-color: #409eff;
        }
      }
    }
  }
  ::v-deep .page-banner{
    position: relative;
    margin-top: -120px;
    z-index: 0;
    .el-carousel__container{
      height: 100vh;

      .el-carousel__item{
        //position: relative;
        display: flex;
        justify-content: center;
        img{
          width: 100%;
          object-fit: cover;
        }

        .page-banner-content{
          position: absolute;
          top: 50%;
          wdith:100%;
          transform: translateY(-50%);
          text-align: center;
        }

        .page-banner-title{
          font-size: 24px;
        }

        .page-banner-subtitle{
          margin-top: 8px;
          max-width: 1000px;
          font-size: 68px;
        }

        .page-banner-info{
          margin: 16px auto 0;
          max-width: 800px;
          font-size: 16px;
          line-height: 1.75;
        }

        .page-banner-line{
          margin-top: 8px;
          span{
            position: relative;
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: rgb(31, 210, 240);
            &:after{
              content: '';
              position: absolute;
              top: 5px;
              right: 25px;
              width: 120px;
              height: 1px;
              background: #ffffff;
            }
            &:before{
              content: '';
              position: absolute;
              top: 5px;
              left: 25px;
              width: 120px;
              height: 1px;
              background: #ffffff;
            }
          }
        }
      }
    }
    .el-carousel__arrow{
      width: 36px;
      height: 60px;
      border-radius: 2px;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      background: rgba(255, 255, 255, .2);
    }

    .icon-aircraft{
      position: absolute;
      bottom: -35px;
      left: 50%;
      width: 70px;
      height: 70px;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  .page-content{
    padding: 80px 16px 40px;
    font-size: 20px;

    .page-nav-header{
      padding-top: 24px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
    .page-nav{
      display: flex;
      max-width: 1200px;
      margin: 40px auto 0;
      .page-nav-item{
        text-align: center;
        width: 25%;
      }
      .page-nav-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 80px;
        height: 80px;
        color: #1fd2f0;
        border: 1px solid #ddd;
        cursor: pointer;

        i{
          font-size: 36px;
        }

        &:hover{
          transform: rotate(45deg);

          i{
            transform: rotate(-45deg);
          }
        }
      }

      .page-nav-title{
        padding: 26px 0;
        height: 90px;
        position: relative;
        font-size: 20px;
        color: #1fd2f0;
        line-height: 1;

        &:before{
          content: '';
          position: absolute;
          left: 50%;
          bottom: 20px;
          transform: translateX(-50%);
          width: 60px;
          height: 2px;
          background: #ccc;
        }
      }

      .page-nav-info{
        padding: 0 12px;
        color: #444444;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  .page-list{
    max-width: 1200px;
    margin: 40px auto 0;
    color: #333333;

    .page-list-item{
      padding: 12px 0;
      text-align: left;
      border-bottom: 1px solid rgba(128, 128, 128, 0.2);
      cursor: pointer;

      .page-list-label{
        display: inline-block;
        position: relative;
        font-size: 16px;
        color: #000;
        font-weight: bold;
        line-height: 22px;
        height: 22px;
        padding-left: 8px;
        //padding: 4px 8px;
        //background: #e5e5e5;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 4px;
          width: 4px;
          background: red;
          height: 12px;
        }
      }

      .page-list-info{
        margin-top: 4px;
        font-size: 13px;
        color: #555555;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  .page-footer-nav{
    display: flex;
    width: 100%;
    height: 500px;
    padding: 16px 16px 0;
    background-image: url("../assets/images/image-footer-bg.png");
    background-color: #23ac38;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .page-footer-box{
      display: flex;
      justify-content: end;
      max-width: 1200px;
      width: 100%;
      margin: 0px auto 0;
      color: #333333;

      .page-footer-panel{
        width: 300px;
        height: 300px;
        padding: 32px 24px;
        background: #ffffff;
        margin-top: 36px;
        text-align: left;

        .page-footer-panel-title{
          font-size: 22px;
          font-weight: bold;
        }

        .page-footer-panel-item{
          display: flex;
          line-height: 1.5;
          margin-bottom: 6px;

          .page-footer-panel-label{
            width: 64px;
          }

          .page-footer-panel-value{
            width: calc(100% - 64px);
          }
        }

        p{
          margin-bottom: 0px;
          line-height: 1.5;
        }
      }
    }



    .footer-nav-row{
      display: flex;
      align-items: center;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      .footer-nav-item{
        width:20%;
        img{
          width: 122px;
          height: 132px;
        }

        .footer-nav-item-value{
          font-size: 36px;
          line-height: 64px;
        }

        .footer-nav-item-label{
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }

  .page-footer{
    background: rgb(32, 32, 32);
    padding: 24px 16px;
    //margin-top: 50px;

    .page-footer-content{
      display: flex;
      align-items: center;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      font-size: 12px;
      line-height: 1.7;

      a{
        color: inherit;
        text-decoration: none;
      }

      .page-footer-left{
        text-align: left;
        width: 50%;
      }
      .page-footer-right{
        text-align: right;
        width: 50%;
      }
    }
  }

  @media screen and (max-width: 899px) {

    .page-header{
      height: auto;

      .page-header-logo{
        img{
          width: 40px;
        }

        .page-header-nav{
          display: none;
        }
      }
    }

    .page-content{
      padding: 40px 16px 16px;
      font-size: 14px;

      .page-nav{
        flex-direction: column;
        margin-top: 0;
        .page-nav-item{
          width: 100%;
          margin-top: 48px;
        }

        .page-nav-icon{
          width: 50px;
          height: 50px;

          i{
            font-size: 28px;
          }
        }

        .page-nav-title{
          font-size: 16px;
        }
      }
    }
    .page-header-tools {
      top: 18px !important;

      .el-button{
        padding: 4px 6px;
        font-size: 11px;
      }

      .support-btn{
        display: none;
      }
    }
    ::v-deep .page-banner{
      .el-carousel__container{
        max-height: 320px;

        .el-carousel__item{
          display: flex;
          justify-content: center;

          .page-banner-content{
            transform: none;
          }
        }

        .page-banner-title{
          margin-top: 0px;
          font-size: 13px !important;
        }
        .page-banner-subtitle{
          margin-top: 6px !important;;
          font-size: 20px !important;
        }
        .page-banner-info,
        .page-banner-line{
          display: none;
        }
      }
      .el-carousel__arrow{
        display: none;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        font-size: 12px;
      }

      .icon-aircraft{
        display: none;
      }
    }

    .page-footer-nav{
      height: 300px;
      padding: 16px;

      .page-footer-box{
        padding: 24px 24px;
        justify-content: center;

        .page-footer-panel{
          height: 224px;
          margin-top: 0px;
        }
      }

      .footer-nav-row{
        flex-wrap:wrap;
        justify-content: center;
        .footer-nav-item{
          width:33.3333333% !important;

          img{
            width: 40px;
            height: 44px;
          }

          .footer-nav-item-value{
            font-size: 18px;
            line-height: 32px;
          }
          .footer-nav-item-label{
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    .page-footer{
      .page-footer-content{
        flex-direction: column;

        .page-footer-left,
        .page-footer-right{
          width: 100%;
          text-align: center;
        }

        .page-footer-right{
          margin-top: 16px;
        }
      }
    }
  }
}

.login-dialog{
  ::v-deep .el-dialog{
    width: 90%;
    max-width: 350px;

    .el-dialog__body{
      padding-bottom: 8px;
    }
  }
}

.pay-dialog{
  ::v-deep .el-dialog{
    width: 260px !important;

    .el-dialog__body{
      padding-top: 0px;
      padding-bottom: 16px;
      img{
        width: 100%;
      }
    }
  }
}
</style>
